.App {
  text-align: center;
  background-image: url("https://images.unsplash.com/photo-1505811210036-052144988918?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1502&q=80");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  overflow: scroll;
  color: white;
}

h2 {
  font-size: 35px;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: 300;
}

h1 {
  font-family:Arial, Helvetica, sans-serif;
}

p {
  font-family: 'PT Serif', serif;
  font-size: 20px;
}

img {
  width: 100%
}

.projectimg {
  width: 500px;
  height: auto;
}

body {
  text-align: center;
}

.project1, .project2, .project3, .projectuxdi, .project4 {
  margin: 200px auto;
  padding: 50px 10% 50px 10%;
  max-width: 70%;
  display: grid;
  grid-template-columns: 6fr 4fr;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  height: 100%;
  width: 95%;
}
.project1:hover, .project2:hover, .project3:hover, .projectuxdi:hover, .project4:hover {
  background-color: rgba(27, 27, 27, 0.7)
}

.project1 {
  margin-top: 600px;
}

.projectimgdiv {
  position: relative;
  width: 500px;
}

.projectimgdiv img {
  border-radius: 7px;
}


.description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity .2s, visibility .2s;
  margin-top: 0;
  margin-bottom: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border-radius: 7px;
}

.projectimgdiv:hover .description{
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.aboutme {
  height: 400px;
  display: block;
}

.aboutme p {
  height: 19em;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a, Link {
  color: rgb(155, 187, 247);
}

a:hover, Link:hover {
  color: rgb(240, 201, 95);
}

.bodyhead {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.name {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  background-image: url("https://cdn.wallpapersafari.com/84/38/WYs03a.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;  
}

.name h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 60px;
  text-shadow: 1px 1px 2px rgb(201, 227, 248), 0 0 1em rgb(0, 0, 0), 0 0 0.2em rgb(0, 0, 0);
  height: 6.5em;
  color: #E6E6E6;
}

.name h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 40px;
  margin-top: -140px;
  text-shadow: 1px 1px 2px rgb(201, 227, 248), 0 0 1em rgb(0, 0, 0), 0 0 0.2em rgb(0, 0, 0);
  height: 2em;
  color: #E6E6E6;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 10px;
}

.contacticons {
  align-self: center;
}

i {
  font-size: 50px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 10px;
}


.techicons i {
  font-size: 95px;
}

.techicons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.oneicon {
  margin-bottom: 70px;
  position: relative;
}

.oneicon p {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  text-shadow: 1px 1px 2px rgb(201, 227, 248), 0 0 1em rgb(0, 0, 0), 0 0 0.2em rgb(0, 0, 0);
}

.oneicondescription {
  position: absolute;
  top: 70px;
  height: 100%;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.oneicon:hover .oneicondescription{
  visibility: visible;
  opacity: 1;
  height: 95px;
}

.personalline {
  margin-left: 50px;
  margin-right: 50px;
}

.fab, .fas {
  transition: all .3s ease-in-out;
}

.fab:hover, .fas:hover {
  transform: scale(1.2)
}

.oneicon:hover {
  transform: scale(1.2)
}

h6 {
  font-size: 25px;
}

a {
  text-decoration: none;
}

.project4 {
  margin-bottom: 100px;
}

.contact {
  display: flex;
  flex-direction: column;
  margin: 0 25%;
  margin-bottom: 100px;
}

.contact input, textarea {
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  border-radius: 3px;
  background-color: rgb(0, 0, 0);
  color: white;
  border: none;
}

.contact a {
  margin-top: 10px;
}



@media only screen and (max-width: 999px) {
  .project1 {
    margin-top: 800px;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .project1, .project2, .project3, .projectuxdi, .project4 {
    display: flex;
    flex-direction: column;
    margin: 50px auto;
  }

  .project1 {
    margin-top: 900px;
  }

  .project1 img, .project2 img, .project3 img, .projectuxdi img, .project4 img {
    width: 300px;
  }

  .projectimgdiv {
    width: 300px;
  }

  h6 {
    font-size: 30px;
  }

}

@media only screen and (max-width: 500px) {
  .project1 {
    margin-top: 1000px;
  }
}

@media only screen and (max-width: 411px) {
  .project1 {
    margin-top: 1100px;
  }
}


@media only screen and (max-width: 374px) {
  .project1 {
    margin-top: 1600px;
  }
}
